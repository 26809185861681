var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"visible":_vm.visible,"title":"Insert/Replace Pages","width":"600px"},on:{"update:visible":function($event){_vm.visible=$event},"cancel":function($event){return _vm.$emit('close')},"ok":_vm.submit}},[_c('div',{staticClass:"modal-body"},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('a-form-item',[(_vm.form.files && _vm.form.files.length)?_c('div',_vm._l((_vm.form.files),function(file,fileI){return _c('strong',{key:file.name,staticClass:"file-name-preview block dF my-2",staticStyle:{"line-height":"1"}},[_vm._v(" "+_vm._s(file.name)+" "),_c('a-icon',{staticClass:"text-danger cursor-pointer",attrs:{"type":"close-circle"},on:{"click":function($event){return _vm.removeFile(fileI)}}})],1)}),0):_c('div',[_c('span',{staticClass:"file-name-preview block dF my-2",staticStyle:{"line-height":"1"}},[_vm._v(" Select PDF file to Insert ")])]),_c('a-button',{attrs:{"icon":"upload"},on:{"click":_vm.selectFiles}},[_vm._v("Upload")]),(_vm.showErrors && (!_vm.form.files || !_vm.form.files.length))?_c('p',{staticClass:"text-danger"},[_c('span',[_vm._v("You must select a PDF")])]):_vm._e()],1),_c('a-form-item',{attrs:{"label":"Where to place","prop":"description"}},[_c('a-radio-group',{model:{value:(_vm.form.where),callback:function ($$v) {_vm.$set(_vm.form, "where", $$v)},expression:"form.where"}},[_c('label',{staticClass:"block",class:_vm.form.where === 'beforeFirst'
                                    ? 'text-primary'
                                    : ''},[_c('a-radio',{attrs:{"label":"Before First Page","value":"beforeFirst"}}),_vm._v(" Insert before First page ")],1),_c('label',{staticClass:"block",class:_vm.form.where === 'afterLast' ? 'text-primary' : ''},[_c('a-radio',{attrs:{"label":"After Last Page","value":"afterLast"}}),_vm._v(" Insert after Last page (Page "+_vm._s(_vm.pages.length)+") ")],1),_c('div',[_c('label',{staticClass:"block",class:_vm.form.where === 'afterIndex'
                                        ? 'text-primary'
                                        : ''},[_c('a-radio',{attrs:{"label":"After:","value":"afterIndex"}}),_vm._v(" Insert after Selected page: ")],1),(_vm.form.where === 'afterIndex')?_c('div',{staticClass:"mb-2"},[_c('a-select',{attrs:{"options":_vm.pagesOptions},model:{value:(_vm.form.index),callback:function ($$v) {_vm.$set(_vm.form, "index", $$v)},expression:"form.index"}})],1):_vm._e()]),_c('div',[_c('label',{staticClass:"block",class:_vm.form.where === 'replaceIndex'
                                        ? 'text-primary'
                                        : ''},[_c('a-radio',{attrs:{"label":"Replace:","value":"replaceIndex"}}),_vm._v(" Selected page from where you want to start replace: ")],1),(_vm.form.where === 'replaceIndex')?_c('div',{staticClass:"mb-2 ml-4"},[_c('a-select',{attrs:{"options":_vm.pagesOptions},model:{value:(_vm.form.index),callback:function ($$v) {_vm.$set(_vm.form, "index", $$v)},expression:"form.index"}})],1):_vm._e()]),(_vm.form.where === 'replaceIndex')?_c('div',{staticClass:"mb-2 ml-4"},[_c('label',{staticClass:"block",class:_vm.form.where === 'replaceIndex'
                                        ? 'text-primary'
                                        : ''},[_vm._v(" Enter the number of pages you want to replace: ")]),_c('a-input-number',{attrs:{"id":"numberOfPagesToReplace","min":1},model:{value:(_vm.numberOfPagesToReplace),callback:function ($$v) {_vm.numberOfPagesToReplace=$$v},expression:"numberOfPagesToReplace"}})],1):_vm._e(),(_vm.form.where === 'replaceIndex')?_c('div',{staticClass:"mb-2 ml-4"},[_c('label',{staticClass:"block",class:_vm.form.where === 'replaceIndex'
                                        ? 'text-primary'
                                        : ''},[_vm._v(" Keep the current field placement in new pages: ")]),_c('a-switch',{attrs:{"id":"keepPageFields"},model:{value:(_vm.keepPageFields),callback:function ($$v) {_vm.keepPageFields=$$v},expression:"keepPageFields"}})],1):_vm._e()])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }