<template>
    <a-modal
        :visible.sync="visible"
        @cancel="$emit('close')"
        @ok="submit"
        title="Shuffle/Move Page"
        width="600px"
    >
        <div class="modal-body">
            <a-form-model :model="form" ref="form">
                <a-form-item
                    :label="`Where to place page ${data.index + 1}`"
                    prop="description"
                >
                    <a-radio-group v-model="form.where" class="mt-3">
                        <label
                            v-if="data.index !== 0"
                            class="block"
                            :class="
                                form.where === 'beforeFirst'
                                    ? 'text-primary'
                                    : ''
                            "
                        >
                            <a-radio
                                label="Before First Page"
                                value="beforeFirst"
                            />
                            Move before First page
                        </label>
                        <label
                            v-if="data.index !== pages.length - 1"
                            class="block"
                            :class="
                                form.where === 'afterLast' ? 'text-primary' : ''
                            "
                        >
                            <a-radio
                                label="After Last Page"
                                value="afterLast"
                            />
                            Move after Last page (Page {{ pages.length }})
                        </label>
                        <div>
                            <label
                                class="block"
                                :class="
                                    form.where === 'afterIndex'
                                        ? 'text-primary'
                                        : ''
                                "
                            >
                                <a-radio label="After:" value="afterIndex" />
                                Move after Selected page:
                            </label>
                            <div
                                v-if="form.where === 'afterIndex'"
                                class="mb-2"
                            >
                                <a-select
                                    v-model="form.index"
                                    :options="pagesOptions"
                                />
                            </div>
                        </div>
                    </a-radio-group>
                </a-form-item>
            </a-form-model>
        </div>
    </a-modal>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        contract: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        form: {
            index: 0,
            where: "afterIndex",
        },
    }),
    watch: {
        visible(val) {
            if (val) {
                this.form.where = "afterIndex";
                this.form.index = !this.data.index
                    ? 1
                    : this.data.index === 1
                    ? 2
                    : 0;
            }
        },
    },
    computed: {
        pages() {
            return this.contract.pages || [];
        },
        pagesOptions() {
            return this.pages
                .map((page, pageI) => {
                    return {
                        label: `Page ${pageI + 1}`,
                        value: pageI,
                    };
                })
                .filter(
                    (page, pageI) =>
                        !(
                            pageI === this.data.index ||
                            pageI + 1 === this.data.index ||
                            (this.data.index === 0 &&
                                this.data.index === pageI) ||
                            (this.data.index === this.pages.length - 1 &&
                                this.data.index === pageI) ||
                            (this.pages.length - 1 === this.data.index &&
                                this.pages.length - 1 === pageI)
                        )
                );
        },
        visible() {
            return this.data.show || false;
        },
    },
    methods: {
        submit() {
            let index = this.form.index;
            if (this.form.where === "afterLast") {
                index = this.pages.length;
            } else if (this.form.where === "beforeFirst") {
                index = 0;
            } else if (this.form.where === "afterIndex") {
                index = index + 1;
            }

            const newPages = [...this.pages];
            newPages.splice(index, 0, newPages.splice(this.data.index, 1)[0]);

            this.$emit("updatePages", newPages);
        },
    },
};
</script>

<style>
</style>
